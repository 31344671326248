<template>
  <div>
    <div id="v8_qrcode" ref="qrCodeDiv"></div>
    <!-- 创建一个div，并设置id为qrcode -->
  </div>
</template>

<script>
import QRCode from "qrcodejs2"; // 引入qrcode
export default {
  name: "qrcode",
  props: {
    width: {
      //宽
      type: Number,
      default: 320
    },
    height: {
      //高
      type: Number,
      default: 320
    },
    text: {
      //内容
      type: String,
      default: ""
    },
    colorDark: {
      //前景色
      type: String,
      default: "#000000"
    },
    colorLight: {
      //背景色
      type: String,
      default: "#ffffff"
    },
    correctLevel: {
      //容错率[L|M|Q|H]
      type: String,
      default: "Q"
    }
  },
  watch: {
    text: {
      handler(n) {
        console.log(n);
        this.$nextTick(() => {
          this.$refs.qrCodeDiv.innerHTML = "";
          let o = {};
          o = new QRCode(this.$refs.qrCodeDiv, {
            width: this.width,
            height: this.height,
            text: n,
            colorDark: this.colorDark,
            colorLight: this.colorLight,
            correctLevel: QRCode.CorrectLevel[this.correctLevel]
          });
        });
      },
      immediate: true
    }
  }
};
</script>
<style lang="less" scoped>
#v8_qrcode {
  width: 4.52rem !important;
  height: 4.52rem !important;
  img{
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
